:root {
    --brand-wander-green: theme(colors.green);
    --brand-navy: theme(colors.navy);
    --brand-charcoal: theme(colors.charcoal);
    --brand-hover: theme(colors.hover);
    --brand-canvas: theme(colors.canvas);
    --brand-red: theme(colors.red);
    --brand-violet: theme(colors.violet);
    --brand-yellow: theme(colors.yellow);
    --brand-light-blue: theme(colors.blue);
    --brand-purple: theme(colors.purple);
    --card-base-height: 352px;
    font-size: 62.5%;
  }

  .has-skillshare-wander-green-color {
    color: theme(colors.green);
  }
  .has-skillshare-wander-green-color:visited,
  .has-skillshare-wander-green-color:active {
    color: theme(colors.green);
  }

  .has-skillshare-wander-green-background-color {
    background-color: theme(colors.green);
  }

  .has-skillshare-navy-color {
    color: theme(colors.navy);
  }
  .has-skillshare-navy-color:visited,
  .has-skillshare-navy-color:active {
    color: theme(colors.navy);
  }

  .has-skillshare-navy-background-color {
    background-color: theme(colors.navy);
  }

  .has-skillshare-charcoal-color {
    color: theme(colors.charcoal);
  }
  .has-skillshare-charcoal-color:visited,
  .has-skillshare-charcoal-color:active {
    color: theme(colors.charcoal);
  }

  .has-skillshare-charcoal-background-color {
    background-color: theme(colors.charcoal);
  }

  .has-skillshare-canvas-color {
    color: theme(colors.canvas);
  }
  
  .has-skillshare-canvas-color:visited,
  .has-skillshare-canvas-color:active {
    color: theme(colors.canvas);
  }

  .has-skillshare-canvas-background-color {
    background-color: theme(colors.canvas);
  }

  .has-skillshare-red-color {
    color: theme(colors.red);
  }
  .has-skillshare-red-color:visited,
  .has-skillshare-red-color:active {
    color: theme(colors.red);
  }

  .has-skillshare-red-background-color {
    background-color: theme(colors.red);
  }

  .has-skillshare-violet-color {
    color: theme(colors.violet);
  }
  .has-skillshare-violet-color:visited,
  .has-skillshare-violet-color:active {
    color: theme(colors.violet);
  }

  .has-skillshare-violet-background-color {
    background-color: theme(colors.violet);
  }

  .has-skillshare-yellow-color {
    color: theme(colors.yellow);
  }
  .has-skillshare-yellow-color:visited,
  .has-skillshare-yellow-color:active {
    color: theme(colors.yellow);
  }

  .has-skillshare-yellow-background-color {
    background-color: theme(colors.yellow);
  }

  .has-skillshare-light-blue-color {
    color: theme(colors.blue);
  }
  .has-skillshare-light-blue-color:visited,
  .has-skillshare-light-blue-color:active {
    color: theme(colors.blue);
  }

  .has-skillshare-light-blue-background-color {
    background-color: theme(colors.blue);
  }

  .has-skillshare-purple-color {
    color: theme(colors.purple);
  }
  .has-skillshare-purple-color:visited,
  .has-skillshare-purple-color:active {
    color: theme(colors.purple);
  }

  .has-skillshare-purple-background-color {
    background-color: theme(colors.purple);
  }

  .has-skillshare-white-color {
    color: #fff;
    &:visited,
    &:active {
      color: #fff;
    }
  }

  .has-skillshare-white-background-color {
    background-color: #fff;
  }

  .has-skillshare-black-color {
    color: #000;
    &:visited,
    &:active {
      color: #000;
    }
  }

  .has-skillshare-black-background-color {
    background-color: #000;
  }