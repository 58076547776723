.post-content {
  /* Post Content styles temporary fix -- to be deleted eventually */
  /* Note: this is to preserve post-content styling on posts made prior to Gutenberg */
  li {
    font-size: 1.9rem;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
    }
    line-height: 3.9rem;
    margin-bottom: 2.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 32px;
  }

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  h2 {
    scroll-margin-top: 128px;
  }

  ul,
  ol {
    li {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 3rem;
    }
  }
  ul {
    li {
      list-style: circle;
    }
  }
  ol {
    li {
      list-style: decimal;
    }
  }
  /* End temporary fix */

  .post-cta {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: var(--brand-charcoal);
    margin: 4rem auto;

    &--outside {
      width: 85%;
      @media screen and (min-width: 1024px) {
        margin: 0 !important;
      }
    }

    @media screen and (min-width: 768px) {
      width: 100%;
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      margin: 5rem 0;
      &.tall {
        max-height: 500px;

        & .wp-block-image {
          height: 500px;

          & img {
            max-height: 500px;
          }
        }
      }
    }

    .wp-block-image {
      display: flex;
      justify-content: center;
      min-height: 200px;
      width: 100%;
      margin: 0;

      figure {
        height: 100% !important;
        position: absolute !important;
        margin: 0 !important;
        width: 100%;
        height: 100% !important;
        span {
          height: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 400px;
        object-position: center;

        @media screen and (min-width: 1024px) {
          max-height: unset;
        }
      }

      @media screen and (min-width: 1024px) {
        align-self: stretch;
        justify-content: flex-end;
        width: 50%;
      }
    }

    .wp-block-image .aligncenter {
      float: none;
      margin: 0;
    }

    .wp-block-group {
      width: 100%;
      height: 100%;
      padding: 60px 0;

      @media screen and (min-width: 1024px) {
        width: 50%;
      }

      & > * {
        margin: 0 auto;
        max-width: 80%;
      }

      h2 {
        color: #fff;
        font-weight: bold;
        font-size: 3.8rem;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        line-height: 4.4rem;
      }

      p {
        font-size: 2rem;
        line-height: 2.9rem;
        color: rgba(255, 255, 255, 0.8);
        margin-top: 2rem;
      }

      .wp-block-button {
        margin-top: 2rem;

        &__link {
          text-align: center;
          padding: 0.8rem 1.2rem;
          display: inline-block;
          text-decoration: none;
          border-radius: 4px !important;
          font-weight: bold;
          font-size: 1.3rem;
          &::after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &.wp-block-heading {
      margin-bottom: 32px;
    }
  }

  h1,
  h2,
  h3 {
    &.wp-block-heading {
      font-weight: bold;
    }
  }

  .wp-block-list {
    li {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 3rem;
    }
    ul {
      li {
        list-style: circle;
      }
    }
    ol {
      li {
        list-style: decimal;
      }
    }
  }

  .wp-block-image {
    figure {
      margin: 5rem 0;
      max-width: 100%;

      img {
        object-fit: contain;
        height: auto;
        @media screen and (min-width: 768px) {
          object-fit: cover;
        }
        max-width: 100%;
      }

      figcaption {
        font-size: 1.6rem;
        text-align: left;
        padding: 1rem 0;
      }
    }
  }

  iframe {
    margin: 2.5rem 0;
    max-width: 100%;
  }

  .blocks-gallery-item figure {
    display: block;

    figcaption {
      position: static;
      background: none;
      font-size: 1.6rem;
      text-align: left;
      padding: 1rem 0;
      color: var(--brand-charcoal);
    }
  }

  .skillshare-protip {
    box-sizing: border-box;
    margin: 4rem 0;
  }
  .skillshare-protip__box {
    background-color: #fff;
    padding: 3.8rem 4.5rem;
    font-size: 1.9rem;
    line-height: 34px;
  }
  @media (min-width: 768px) {
    .skillshare-protip__box {
      font-size: 2.2rem;
    }
  }
  .skillshare-protip__box h5 {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 5px 0 1.75rem;
  }
  @media (min-width: 768px) {
    .skillshare-protip__box h5 {
      font-size: 2.6rem;
    }
  }

  .sk-featured-snippet {
    background: white;
    padding: 4rem 2rem;
    h2 {
      border: 1px solid var(--brand-charcoal);
      padding: 16px;
      margin: 0;
    }
  }

  .skillshare-pull-quote {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-bottom: 7rem;
    margin: 4rem 0;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }

    &__quote {
      font-style: italic;
      font-weight: bold;
      font-size: 2.3rem;

      @media (min-width: 768px) {
        font-size: 2.7rem;
      }
    }

    &__said-by {
      font-style: italic;
      margin-top: 1.75rem;
      font-size: 1.9rem;

      @media (min-width: 768px) {
        font-size: 2.2rem;
      }
    }

    &__content {
      position: absolute;
      background: #fff;
      height: auto;
      color: var(--brand-charcoal, #394649);
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      max-width: unset;
      padding: 3.8rem 4.5rem;

      @media (min-width: 768px) {
        width: 50%;
      }

      @media (min-width: 768px) {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &--imageless {
        position: relative;
        width: 100%;
        max-width: 100%;
        display: block;
        transform: revert;
      }
    }

    &__upload {
      width: 100%;
      height: 348px;
      border: none;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: repeating-linear-gradient(
        45deg,
        #fff,
        #fff 10px,
        #f0f0f0 10px,
        #f0f0f0 20px
      );

      @media (min-width: 768px) {
        width: 80%;
      }
    }
  }

  .wp-block-image {
    position: relative;
  }

  .wp-block-columns {
    .wp-block-column {
      &:not(:first-child) {
        margin-left: 2rem;
        @media screen and (max-width: 781px) {
          margin-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }

  .recommended-classes-block {
    .btn {
      align-items: center;
      display: inline-flex;
    }
  }

  .wp-block-embed-twitter {
    .wp-block-embed__wrapper {
      .twitter-tweet-rendered {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .featured-posts-block {
    h2 {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1280px) {
      display: block;
      .normal-card {
        margin-bottom: 3.2rem;
      }
    }
    @media screen and (max-width: 1024px) {
      .normal-card {
        margin-bottom: 0;
      }
    }
  }
}

.post {
  .global-footer-cta {
    display: none;
    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }

  .authorship-card-carousel {
    &:has(+ .dynamic-toc) {
      @media screen and (min-width: 768px) {
        margin-right: 4px;
        width: calc(50% - 2px);
      }
      @media screen and (min-width: 1024px) {
        margin-right: 0;
        width: 100%;
      }
    }
    & + .dynamic-toc {
      @media screen and (min-width: 768px) {
        margin-left: 4px;
        width: calc(50% - 2px);
      }
      @media screen and (min-width: 1024px) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.page {
  .sk-max-width-block {
    .max-width-inner-blocks {
      margin-left: auto;
      margin-right: auto;
      max-width: 1136px;
    }
  }
}

/** Rules for the Newsletter Signup block */
.newsletter-signup {
  container-type: inline-size;
  container-name: newsletter-signup;

  .newsletter-signup__background {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    @container newsletter-signup (min-width: 520px) {
      background-position: 0 157px;
      background-size: 129%;
    }

    @container newsletter-signup (min-width: 704px) {
      background-position: 270px 27px;
      background-size: 715px 356px;
    }

    @container newsletter-signup (min-width: 896px) {
      background-position: 544px 0;
    }

    @container newsletter-signup (min-width: 1024px) {
      background-position: 544px 0;
    }
  }
}

/* REMOVE WITH GATED CONTENT ROLLOUT */
.sk-max-width-block {
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 64px;
    padding-right: 64px;
  }
}
.sk-max-width-block > div {
  margin: 0 auto;
  max-width: 1185px;
}

.wp-block-paragraph,
.wp-block-list li {
  color: #002333;
  font-size: 1.9rem;
  @media screen and (min-width: 768px) {
    font-size: 2.2rem;
  }
  line-height: 3.9rem;
  margin-bottom: 2.5rem;
}
