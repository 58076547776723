@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
  }

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0; /* 1 */
    border-color: inherit; /* 2 */
  }

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  summary {
    display: list-item;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.default', currentColor);
  }

  button {
    background: none;
  }

  @charset "UTF-8";

  html :where(.has-border-color) {
    border-style: solid;
  }

  html :where([style*='border-width']) {
    border-style: solid;
  }

  html :where(img[class*='wp-image-']) {
    height: auto;
    max-width: 100%;
  }

  html {
    background-color: theme(colors.navy);
  }
  body {
    background-color: theme(colors.canvas);
    color: theme(colors.navy);
    font-family: theme(fontFamily.sans);
    font-size: theme(fontSize.base);
  }
  h1,
  h2 {
    color: theme(colors.navy);
  }
  h1,
  h2,
  h3 {
    font-weight: 700;
  }
  h1 {
    font-size: theme(fontSize.2xl);
    line-height: 46px;
  }
  h2 {
    font-size: theme(fontSize.xl);
    line-height: 34px;
  }
  h3 {
    font-size: theme(fontSize.lg);
    line-height: 32px;
  }
  h4 {
    font-size: theme(fontSize.med);
    line-height: 28px;
  }

  body.single {
    background: #e5e5e5;
  }

  img {
    object-fit: cover;
    max-width: 100%;
  }

  a {
    color: theme(colors.violet);
    text-decoration: none;
  }
  a:hover {
    color: theme(colors.purple);
  }
  a:active {
    color: theme(colors.navy);
  }

  .screen-reader-text,
  .sharing-screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }

  .screen-reader-text:focus,
  .sharing-screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
  }

  .tooltip-icon {
    &:hover {
      .tooltip-text {
        display: block;
      }
    }
  }
}

@layer components {
}
